import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import { signOut } from "aws-amplify/auth";

import { webAuthnRegister } from "../../../../webauthn";

import FlowNavbar from "components/Navbars/FlowNavbar";
import ProgressBar from "../../../components/progressBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const PrimaryPasskey = (props) => {
  const history = useHistory();

  const stepNumber = 3;

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [errorMessage, setErrorMessage] = useState();

  const registerUser = async ({ email }) => {
    if (email === "") {
      setErrorMessage("Email is required");
    } else {
      setLoading(true);

      /*
       * NOTE:
       *
       * This is desirable because Amplify version 6 does not permit
       * multiple active sessions (including sessions for account being created
       * and/or during verification)
       *
       * For more information, see:
       * https://github.com/aws-amplify/amplify-js/issues/13070#issuecomment-2275797232
       *
       */
      await signOut({ global: true });

      try {
        const lowerCaseEmail = email.toLowerCase(); // Required for Passkey interoperability
        const result = await webAuthnRegister({
          displayName: lowerCaseEmail,
          name: lowerCaseEmail,
          username: lowerCaseEmail,
        });
        if (result.status === "failed") {
          setErrorMessage(result.message);
        } else {
          setLoading(false);

          // Required to prep verify page
          localStorage.setItem("unverififedEmail", email);

          // Redirect user to verification page
          history.push("/lodge/company-registration/verify-email");
        }
      } catch (e) {
        setErrorMessage(e.message);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title={`${localStorage.getItem("companyName")} PTY LTD`}
          step={stepNumber}
          stepCount={5}
          stepName="Registration Deatils"
        />
        <br />

        <div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await submitForm();
            }}
          >
            <Card className="text-left shadow border-1 mb-5">
              <CardHeader>Step {stepNumber}. Primary Passkey </CardHeader>
              <CardBody>
                <h3>Register Passkey</h3>
                To secure your company, we require you to create a passkey.
                <br />
                This will ensure only you can authorise changes to the company.
                <br />
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-right d-none d-sm-block">
                    Email
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        localStorage.setItem("email", e.target.value);
                        setEmail(e.target.value);
                      }}
                      invalid={errorMessage ? true : false}
                    />
                    <FormFeedback>
                      {errorMessage === "" ? null : (
                        <p className="text-left text-danger">{errorMessage}</p>
                      )}
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <br />
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  onClick={() => registerUser({ email })}
                  color="success"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}{" "}
                  Create Passkey <FaArrowRight />
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Container>
      <SimpleFooter />
    </>
  );
};

export default PrimaryPasskey;

import { MdReceipt } from "react-icons/md";

import Dashboard from "views/pages/Dashboard";

import ListRegistrations from "views/pages/Registration/ListRegistrations";

import EditCustomer from "views/pages/Customer/EditCustomer";
import AddCustomer from "views/pages/Customer/AddCustomer";
import ListCustomers from "views/pages/Customer/ListCustomers";

import Schedule from "views/pages/Scheduling/Schedule";
import AddScheduledAppointment from "views/pages/Scheduling/AddScheduledAppointment";

import LiveInvoiceDetail from "views/pages/Invoice/LiveInvoiceDetail";
import ListInvoices from "views/pages/Invoice/ListInvoices";

import ExpenseDetail from "views/pages/Expense/ExpenseDetail";
import ListExpenses from "views/pages/Expense/ListExpenses";

import IdentityVerification from "views/pages/Banking/IdentityVerification";
import WeelBanking from "views/pages/Banking/WeelBanking";
import NativeBanking from "views/pages/Banking/NativeBanking";

import MonoovaBanking from "views/pages/Banking/MonoovaBanking";
import ListBanks from "views/pages/Banking/BankList";

import AUITRDetail from "./views/pages/Tax/AUBASDetail";
import AUBASDetail from "./views/pages/Tax/AUBASDetail";
import ListTaxObligations from "views/pages/Tax/ListTaxObligations";

import ListDocuments from "views/pages/Document/ListDocuments";

import ListPasskeys from "views/pages/Settings/ListPasskeys";
import EditSettings from "views/pages/Settings/EditSettings";

import CompanyDetail from "views/pages/Company/Dashboard";

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: (profile) => <Dashboard businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/payment-accounts/:uuid",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: (profile, features) => (
      <MonoovaBanking businessProfile={profile} features={features} />
    ),
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/payment-accounts",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: (profile, features) => (
      <ListBanks businessProfile={profile} features={features} />
    ),
    layout: "/dashboard",
  },
  {
    path: "/customers/:uuid/edit",
    name: "Customers",
    icon: "ni ni-single-02 text-purple",
    component: (profile) => <EditCustomer businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/customers/add",
    name: "Customers",
    icon: "ni ni-single-02 text-purple",
    component: (profile) => <AddCustomer businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-single-02 text-purple",
    component: (profile) => <ListCustomers businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/scheduling/:uuid",
    name: "Scheduling",
    icon: "ni ni-calendar-grid-58 text-orange",
    component: () => <AddScheduledAppointment />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/scheduling",
    name: "Scheduling",
    icon: "ni ni-calendar-grid-58 text-orange",
    component: () => <Schedule />,
    layout: "/dashboard",
  },
  {
    path: "/live-invoice/:uuid",
    name: "Invoicing",
    icon: "ni ni-single-copy-04 text-info",
    component: () => <LiveInvoiceDetail />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/invoices",
    name: "Invoicing",
    icon: "ni ni-single-copy-04 text-info",
    component: (_, features) => <ListInvoices features={features} />,
    layout: "/dashboard",
  },
  {
    path: "/payments/identity-verification",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: (profile) => <IdentityVerification businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/expenses/:uuid",
    name: "Expenses",
    reactIcon: (
      <MdReceipt
        size={18}
        color={"#2dce89"}
        style={{ marginLeft: "-1px", marginRight: "30px" }}
      />
    ),
    component: (_, features) => <ExpenseDetail features={features} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/expenses",
    name: "Expenses",
    reactIcon: (
      <MdReceipt
        size={18}
        color={"#2dce89"}
        style={{ marginLeft: "-1px", marginRight: "30px" }}
      />
    ),
    component: (_, features) => <ListExpenses features={features} />,
    layout: "/dashboard",
  },
  {
    path: "/banking/weel",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: () => <WeelBanking />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/card",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: (_, features) => <CardAccount features={features} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/banking",
    name: "Payments",
    icon: "ni ni-money-coins text-success",
    component: (_, features) => <NativeBanking features={features} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/tax/au-itr/:uuid",
    name: "Tax",
    icon: "ni ni-check-bold text-blue",
    component: (profile) => <AUITRDetail businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/tax/au-bas/:uuid",
    name: "Tax",
    icon: "ni ni-check-bold text-blue",
    component: (profile) => <AUBASDetail businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/tax",
    name: "Tax",
    /*reactIcon: (
      <FontAwesomeIcon
      name="landmark-flag"
        size={18}
        color={"#00000080"}
        style={{ marginLeft: "-1px", marginRight: "30px" }}
      />
    ),*/
    icon: "ni ni-check-bold text-blue",
    component: (profile) => <ListTaxObligations businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/documents",
    name: "Documents",
    icon: "ni ni-folder-17 text-yellow",
    component: (profile) => <ListDocuments businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/registrations",
    name: "Registrations",
    icon: "ni ni-bullet-list-67 text-warning",
    component: (profile) => <ListRegistrations businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/company",
    name: "Company",
    icon: "ni ni-building text-blue",
    component: (profile) => <CompanyDetail businessProfile={profile} />,
    layout: "/dashboard",
  },
  {
    path: "/settings/passkeys",
    name: "Passkeys",
    icon: "ni ni-settings-gear-65",
    component: (profile) => <ListPasskeys businessProfile={profile} />,
    layout: "/dashboard",
    invisible: true,
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65",
    component: (profile) => <EditSettings businessProfile={profile} />,
    layout: "/dashboard",
  },
];
export default routes;

import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import { confirmSignUp } from "aws-amplify/auth";

import FlowNavbar from "components/Navbars/FlowNavbar";
import ProgressBar from "../../../components/progressBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const CompanyMemberVerifyEmail = () => {
  const history = useHistory();

  const stepNumber = 3;

  /*
   *  State variables
   */
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
   *  Registration action
   */
  const verifyUser = async ({ email, verificationCode }) => {
    if (verificationCode === "") {
      setErrorMessage("Email and Verification Code are both required");
    } else {
      setLoading(true);

      try {
        const { isSignUpComplete } = await confirmSignUp({
          username: email,
          confirmationCode: verificationCode,
        });

        if (isSignUpComplete) {
          //await autoSignIn();

          history.push("/lodge/company-registration/registered-office");
        }
      } catch (e) {
        console.log(e.message);
        setErrorMessage(`Error: ${e.message}`);

        setLoading(false);
      }
    }
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title={`${localStorage.getItem("companyName")} PTY LTD`}
          step={stepNumber}
          stepCount={5}
          stepName="Registration Deatils"
        />
        <br />

        <div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await submitForm();
            }}
          >
            <Card className="text-left shadow border-1 mb-5">
              <CardHeader>Step {stepNumber}. Verify Email</CardHeader>
              <CardBody>
                <h3>Verify Email</h3>
                We have sent you a verification code by email.
                <br />
                Please provide it below to continue.
                <br />
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-right d-none d-sm-block">
                    Verification Code
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      placeholder="Verification Code"
                      autofocus={true}
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      invalid={errorMessage ? true : false}
                    />
                    <FormFeedback>
                      {errorMessage === "" ? null : (
                        <p className="text-left text-danger">{errorMessage}</p>
                      )}
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <br />
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  onClick={() => verifyUser({ email, verificationCode })}
                  color="success"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}{" "}
                  Continue <FaArrowRight />
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Container>
      <SimpleFooter />
    </>
  );
};

export default CompanyMemberVerifyEmail;

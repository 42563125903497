import React from "react";
import {
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Button,
} from "reactstrap";

const CustomerNavbar = (props) => {
  return (
    <>
      <Navbar
        className="navbar-light bg-white mb-4"
        expand="lg"
        style={{ zIndex: 10 }}
      >
        <Container>
          <NavbarBrand onClick={() => {}} style={{ textTransform: "none" }}>
            {props.merchantName}
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
            {props.actionDisabled && props.invoice?.status !== "EXPENSE" ? (
              <Button
                color="primary"
                href={`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${props.invoiceUUID}/download`}
                size="md"
              >
                Download PDF
              </Button>
            ) : undefined}
            {props.invoice?.status === "EXPENSE" ? (
              <Button
                color="primary"
                onClick={() => {
                  props.setAttachmentModalIsOpen(true);
                }}
                size="md"
              >
                View Attachment
              </Button>
            ) : undefined}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomerNavbar;

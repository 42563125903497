import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { getAuthorization } from "webauthn/session";
//import { getPasskeyJWTAsync } from 'webauthn/securityStepUp';
import {
  securityStepUpRequired,
  getPasskeyLegalEntityUUID,
  getPasskeyJWTAsync,
} from "../../../webauthn/securityStepUp";

const ListCustomers = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty`,
            {
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  props.businessProfile?.principal_legal_entity
                    ?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            const json = await response.json();
            setCustomers(
              json.counterparties.filter(
                (counterparty) => !counterparty.supplier
              )
            );
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      } else {
        const authorization = await getAuthorization();
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact`,
            {
              headers: {
                Authorization: authorization,
              },
            }
          );

          const json = await response.json();
          setCustomers(json.contacts);
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      }
    })();
  }, [setCustomers]);

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Customers</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() =>
                        (window.location.href = "/dashboard/customers/add")
                      }
                      size="sm"
                    >
                      Add Customer
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Contact Name</th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Created Date</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <div className="icon icon-shape text-grey rounded-circle shadow">
                              <i className="fas fa-user" />
                            </div>
                            <Media>
                              <span className="mb-0 ml-5 text-sm">
                                {customer.first_name} {customer.last_name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{customer.business_name}</td>
                        <td>{customer.email}</td>
                        <td>
                          {DateTime.fromISO(customer.created_at).toLocaleString(
                            DateTime.DATETIME_MED
                          )}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) =>
                                  (window.location.href = `/dashboard/customers/${customer.uuid}/edit`)
                                }
                              >
                                Edit Customer
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListCustomers;

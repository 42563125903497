import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
import { getAuthorization } from "webauthn/session";

import { KYCModal } from "./kycModal";

const ListBanks = (props) => {
  const history = useHistory();

  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [bankAccounts, setBankAccounts] = useState([]);

  const [kycModalIsOpen, setKYCModalIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.legal_entity_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/payment-account`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );
        const json = await response.json();

        //return json.accounts;
        setBankAccounts(json.accounts);
      }

      /*const response = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/bank-account`, {
        headers: {
          Authorization: await getAuthorization(),
        },
      })
      const json = await response.json()
      setBankAccounts(json.bank_accounts)*/

      if (
        props.businessProfile?.identity_verification_status === "REQUIRED" &&
        !props.features.skipUserKYC?.enabled
      ) {
        //history.push('/dashboard/payments/identity-verification')
      }
    })();
  }, [setBankAccounts]);

  const paymentAccountMap = (bankCode) => {
    let result;
    switch (bankCode) {
      case "tpa":
        result = "/dashboard/banking";
        break;
    }

    return result;
  };

  const applyNow = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/payment-account/apply`,
      {
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify({}),
      }
    );

    if (response.ok) {
      alert("A team member will reach out shortly to open your account!");
    }
  };

  return (
    <>
      <KYCModal isOpen={kycModalIsOpen} setIsOpen={setKYCModalIsOpen} />
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Accounts</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {/*<Button
                      color="success"
                      onClick={() => window.location.href = '/dashboard/customers/add'}
                      size="sm"
                    >
                      Add Customer
                    </Button>*/}
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Account</th>
                    <th scope="col">BSB</th>
                    <th scope="col">Account Number</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {bankAccounts.length === 0 &&
                  props.businessProfile?.identity_verification_status ===
                    "SUBMITTED" ? (
                    <tr>
                      <td colspan="3">
                        <p className="text-center">
                          Your Identity Documents have been successfully
                          submitted. We will contact you once your identity has
                          been verified
                        </p>
                      </td>
                    </tr>
                  ) : undefined}
                  {bankAccounts.length === 0 ? (
                    <tr>
                      <th colSpan="3">
                        <h3 className="text-center">
                          Open a Business Transaction Account
                        </h3>
                        <p className="text-center">
                          An accountedfor business transaction account will take
                          your business to the next level of efficiency
                        </p>
                        <p className="text-center">
                          <Button color="success" onClick={() => applyNow()}>
                            Apply Now
                          </Button>
                        </p>
                      </th>
                    </tr>
                  ) : undefined}
                  {bankAccounts.map((bankAccount, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          {bankAccount.bank_code === "tpa"
                            ? "Operating Account"
                            : bankAccount.account_label}
                          <span className="ml-2">
                            {bankAccount.sandbox ? (
                              <Badge color="warning" pill>
                                Sandbox
                              </Badge>
                            ) : undefined}
                          </span>
                        </th>
                        <td>{bankAccount.bsb}</td>
                        <td>{bankAccount.account_number}</td>
                        <td className="text-center" width="200px">
                          <Button
                            color="success"
                            onClick={() =>
                              history.push(
                                `/dashboard/payment-accounts/${bankAccount.uuid}`
                              )
                            }
                            size="sm"
                          >
                            View Transactions
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListBanks;

import { getAuthorization } from "webauthn/session";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

export const getInvoiceByUUID = async (uuid) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.legal_entity_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/${uuid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
      }
    );
    const json = await response.json();

    return json.invoice;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${uuid}`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );
    const json = await response.json();

    return json.invoice;
  }
};

export const getInvoiceAttachment = async (s3ObjectKey) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.legal_entity_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/document-download`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        method: "post",
        body: JSON.stringify({
          s3_object_key: s3ObjectKey,
        }),
      }
    );
    const json = await response.json();

    return json;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document-download`,
      {
        headers: {
          Authorization: authorization,
        },
        method: "post",
        body: JSON.stringify({
          s3_object_key: s3ObjectKey,
        }),
      }
    );
    const json = await response.json();

    return json;
  }
};

import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

const AUBASDetail = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const history = useHistory();
  const params = useParams();

  const [bas, setBAS] = useState();

  useEffect(() => {
    (async () => {
      if (principalLegalEntity) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/tax/au-bas/${params.uuid}`,
            {
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            const json = await response.json();
            setBAS(json.bas);
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      }
    })();
  }, [params.uuid, setBAS]);

  const authoriseBAS = async () => {
    if (principalLegalEntity) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/tax/au-bas/${params.uuid}/authorise-lodgement`,
          {
            headers: {
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity?.legal_entity_uuid,
            },
            method: "post",
          }
        );

        if (response.ok) {
          const json = await response.json();

          if (json.status === "ok") {
            alert("Lodgement Authorised");
          } else {
            alert("Error: Lodgement Not Authorised");
          }
        }
      } catch (e) {
        alert(`An unknown error occurred. (Server said: ${e})`);
      }
    } else {
      alert("Error: Lodgement Not Authorised, Legal Entity Not Specified");
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Business Activity Statement</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">BAS Period:</h6>
                  <div className="pl-lg-4">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Total Sales (G1)</th>
                          <th scope="col">GST on Sales (1A)</th>
                          <th scope="col">GST on Purchases (1B)</th>
                          <th scope="col">Amount</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {new Intl.NumberFormat("en-AU", {
                              style: "currency",
                              currency: "AUD",
                            }).format(parseFloat(bas?.total_sales))}
                          </td>
                          <td>
                            {new Intl.NumberFormat("en-AU", {
                              style: "currency",
                              currency: "AUD",
                            }).format(parseFloat(bas?.gst_on_sales))}
                          </td>
                          <td>
                            {new Intl.NumberFormat("en-AU", {
                              style: "currency",
                              currency: "AUD",
                            }).format(parseFloat(bas?.gst_on_purchases))}
                          </td>
                          <th>
                            {new Intl.NumberFormat("en-AU", {
                              style: "currency",
                              currency: "AUD",
                            }).format(Math.abs(parseFloat(bas?.tax_estimate)))}
                          </th>
                          <td>
                            {parseFloat(bas?.tax_estimate) < 0 ? (
                              <Badge
                                className="text-uppercase ml-3"
                                color="success"
                              >
                                Refundable
                              </Badge>
                            ) : undefined}
                            {parseFloat(bas?.tax_estimate) === 0 ? (
                              <Badge
                                className="text-uppercase ml-3"
                                color="info"
                              >
                                Payable
                              </Badge>
                            ) : undefined}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <Row>
                      <Col lg="6"></Col>
                      <Col lg="6"></Col>
                    </Row>
                    <Row>
                      <Col lg="12"></Col>
                    </Row>
                    <Row>
                      <Col lg="12"></Col>
                    </Row>
                  </div>
                  <br />
                  <br />
                  <br />
                </Form>
              </CardBody>
              <CardFooter>
                <Row className="align-items-center">
                  <Col xs="8"></Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() => authoriseBAS()}
                      size="md"
                      disabled={bas?.filed_at || bas?.lodgement_authorised_at}
                    >
                      {bas?.filed_at || bas?.lodgement_authorised_at
                        ? "Lodgement Authorised"
                        : "Authorise Lodgement"}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AUBASDetail;

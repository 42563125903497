import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardFooter,
  Modal,
} from "reactstrap";

import styled from "styled-components";

import {
  taxDocumentLabel,
  taxDocumentNavbarMerchantName,
  taxDocumentMerchantName,
  taxDocumentAddressLine1,
  taxDocumentAddressLine2,
} from "./taxDocument";

import LiveInvoicePay from "./LiveInvoicePay";

import CustomerNavbar from "../../../components/Navbars/CustomerNavbar";

const TopRightCircleWrapper = styled.div`
  z-index: 5;

  position: absolute;
  @media only screen and (min-width: 768px) {
    width: 350px;
    height: 350px;
    max-width: 20rem;

    top: -150px;
    right: -120px;
  }
  @media only screen and (max-width: 767px) {
    width: 300px;
    height: 300px;
    max-width: 10rem;

    top: -100px;
    right: -100px;
  }
`;

const CirclePink = styled.div`
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: none;

  filter: blur(50px);
  background-color: rgba(234, 85, 237, 0.8);
  border-radius: 100%;
  flex: none;
  transform: translate(-100px);
`;

const CircleBlue = styled.div`
  width: 100%;
  height: 100%;
  max-height: none;
  max-width: none;

  position: relative;

  @media only screen and (min-width: 768px) {
    top: -200px;
    filter: blur(50px);
    transform: translate(75px);
  }
  @media only screen and (max-width: 767px) {
    top: -150px;
    filter: blur(45px);
  }
  mix-blend-mode: multiply;
  background-color: #84caff;
  border-radius: 100%;
  flex: none;
`;

const CustomerLiveInvoice = () => {
  const history = useHistory();
  const params = useParams();

  const [defaultModalIsOpen, setDefaultModalIsOpen] = useState(false);

  const [attachmentModalIsOpen, setAttachmentModalIsOpen] = useState();

  // Old settings
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [invoice, setInvoice] = useState();

  const [showStripeBetaFeature, setShowStripeBetaFeature] = useState(false);

  const getPublicInvoiceByUUID = async (uuid) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${uuid}`
    );
    const json = await response.json();

    return json.invoice;
  };

  useEffect(() => {
    (async () => {
      const invoiceResult = await getPublicInvoiceByUUID(params.uuid);
      setInvoice(invoiceResult);

      console.log(invoiceResult.merchant.abn);
      setShowStripeBetaFeature(
        invoiceResult.merchant.abn === "28631107714" ||
          invoiceResult.merchant.abn === "49672208472" ||
          invoiceResult.merchant.abn === "24903877928" ||
          invoiceResult.merchant.abn === "92368309959" ||
          invoiceResult.merchant.stripe_account
      );
    })();
  }, [params.uuid, setInvoice]);

  /*
   *  Formatting the Currency amounts using native javascript
   *  constructs...
   */
  const subtotal = invoice?.line_items
    .reduce((previous, current) => {
      return previous + current.quantity * current.unit_price;
    }, 0)
    .toFixed(2);

  const subtotalFormatted = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(subtotal);

  const gst = invoice?.line_items
    .reduce((previous, current) => {
      return (
        previous +
        current.quantity *
          current.unit_price *
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 0
            : 1 / parseFloat(current.tax_rate))
      );
    }, 0)
    .toFixed(2);

  const gstFormatted = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(gst);

  const total = invoice?.line_items
    .reduce((previous, current) => {
      return (
        previous +
        current.quantity *
          current.unit_price *
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 1
            : (parseFloat(current.tax_rate) + 1) / 10)
      );
    }, 0)
    .toFixed(2);

  const stripeTotal = Math.round(total * 100);

  const totalFormatted = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
  }).format(total);

  const detectQuantityMinimumSignificantFigures = (total, lineItems) => {
    let minimumSignificantFigures = 4;

    let trialTotal = lineItems
      .reduce((previous, current) => {
        return (
          previous +
          parseFloat(current.quantity).toFixed(minimumSignificantFigures) *
            current.unit_price *
            (parseFloat(current.tax_rate ?? 0) === 0
              ? 1
              : (parseFloat(current.tax_rate) + 1) / 10)
        );
      }, 0)
      .toFixed(2);

    while (minimumSignificantFigures > 0) {
      minimumSignificantFigures -= 1;

      trialTotal = lineItems
        .reduce((previous, current) => {
          return (
            previous +
            parseFloat(current.quantity).toFixed(minimumSignificantFigures) *
              current.unit_price *
              (parseFloat(current.tax_rate ?? 0) === 0
                ? 1
                : (parseFloat(current.tax_rate) + 1) / 10)
          );
        }, 0)
        .toFixed(2);

      if (trialTotal !== total) {
        return minimumSignificantFigures + 1;
      }
    }

    return minimumSignificantFigures;
  };

  return (
    <>
      <Modal
        isOpen={defaultModalIsOpen}
        toggle={() => setDefaultModalIsOpen(!defaultModalIsOpen)}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Continue Editing or Sign Up
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setDefaultModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>This is a test invoice which cannot be paid.</p>
          <p>
            If you're about to send an invoice and still testing,{" "}
            <a href="/dashboard/live-invoice/add">continue editing</a> or if
            you've received this and would like to send a similar invoice,{" "}
            <a href="/sign-up">sign up to accountedfor</a>!
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            href="/dashboard/live-invoice/add"
          >
            Continue Editing
          </Button>
          <Button
            color="success"
            data-dismiss="modal"
            type="button"
            href="/register"
          >
            Sign Up
          </Button>
        </div>
      </Modal>
      <Modal
        className="modal-quote-emtpy"
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(!modalIsOpen)}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Can't Accept an Empty Quote!
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            You've accepted an empty quote! You must either be drafting up a
            quote or gotten here by mistake.
          </p>
          <p>
            To see how a real quote works without sending it to a customer,
            finish drafting and hit <b>Send Me a Test</b>!
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => history.goBack()}
          >
            Finish Drafting
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={attachmentModalIsOpen}
        toggle={() => setAttachmentModalIsOpen(!attachmentModalIsOpen)}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            View Attachment
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAttachmentModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          {invoice?.attachment?.presigned_url?.presigned_url ? (
            <object
              aria-label="Expense Attachment"
              /*type="application/pdf"*/
              data={invoice?.attachment?.presigned_url?.presigned_url}
              width="100%"
              height="100%"
              style={{ minHeight: "650px" }}
            />
          ) : undefined}
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            onClick={() => setAttachmentModalIsOpen(false)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
      <CustomerNavbar
        merchantName={
          invoice?.invoice_serial === "INV-Y1038" ||
          invoice?.invoice_serial === "INV-Y1039"
            ? "Yachtparts.com.au"
            : taxDocumentNavbarMerchantName(invoice?.merchant?.business_name)
        }
        invoiceUUID={params.uuid}
        actionDisabled={true}
        action={() => setDefaultModalIsOpen(true)}
        setAttachmentModalIsOpen={setAttachmentModalIsOpen}
        invoice={invoice}
      />
      <Container>
        <TopRightCircleWrapper>
          <CirclePink />
          <CircleBlue />
        </TopRightCircleWrapper>
        <Card className="text-left shadow border-1 mb-5" style={{ zIndex: 15 }}>
          <CardBody className="p-4">
            <Row>
              <Col xs="6">
                {invoice?.merchant?.logo_url ? (
                  <>
                    <div
                      className="d-none d-sm-block"
                      style={{ maxWidth: "200px", minHeight: "100px" }}
                    >
                      <img
                        src={invoice?.merchant?.logo_url}
                        style={{ maxWidth: "100%" }}
                      />
                    </div>
                    <img
                      src={invoice?.merchant?.logo_url}
                      style={{ maxWidth: "100%" }}
                      className="d-block d-sm-none"
                    />
                    <h6 className="mt-4 mb-0">
                      {invoice?.invoice_serial === "INV-Y1038" ||
                      invoice?.invoice_serial === "INV-Y1039"
                        ? "Yachtparts.com.au"
                        : taxDocumentMerchantName(
                            invoice?.merchant?.business_name
                          )}
                    </h6>
                  </>
                ) : (
                  <h3 className="mt-3 mb-0">
                    {invoice?.invoice_serial === "INV-Y1038" ||
                    invoice?.invoice_serial === "INV-Y1039"
                      ? "Yachtparts.com.au"
                      : taxDocumentMerchantName(
                          invoice?.merchant?.business_name
                        )}
                  </h3>
                )}
                <p className="d-none d-sm-block">
                  {invoice?.merchant?.abn !== "49672208472" &&
                    invoice?.invoice_serial !== "INV-Y1038" &&
                    invoice?.invoice_serial !== "INV-Y1039" &&
                    taxDocumentAddressLine1(
                      invoice?.merchant?.business_address_line_1
                    )}
                  <br />
                  {taxDocumentAddressLine2(
                    invoice?.merchant?.business_address_line_2
                  )}
                </p>
              </Col>
              <Col xs="6">
                <h1 className="text-right">
                  {invoice?.void ? (
                    <>
                      <span>{taxDocumentLabel(invoice?.status)}</span>{" "}
                      <span className="text-danger">(Void)</span>
                    </>
                  ) : (
                    taxDocumentLabel(invoice?.status)
                  )}
                </h1>
                <p></p>
                <p className="text-right">
                  {invoice?.status !== "EXPENSE" &&
                  invoice?.status !== "EXPENSE_PAID" ? (
                    <>
                      <span className="d-none d-sm-block">
                        Invoice Number: {invoice?.invoice_serial}
                        <br />
                      </span>
                      <span className="d-block d-sm-none">
                        {invoice?.invoice_serial}
                        <br />
                      </span>
                    </>
                  ) : undefined}
                  {invoice?.merchant?.abn &&
                  invoice?.status !== "EXPENSE" &&
                  invoice?.status !== "EXPENSE_PAID" &&
                  invoice?.invoice_serial !== "INV-Y1038" &&
                  invoice?.invoice_serial !== "INV-Y1039" ? (
                    <>
                      ABN: {invoice?.merchant?.abn}
                      <br />
                    </>
                  ) : undefined}
                  Date:{" "}
                  {invoice?.effective_date
                    ? DateTime.fromISO(invoice?.effective_date).toLocaleString(
                        DateTime.DATE_SHORT
                      )
                    : ""}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                {invoice?.counterparty?.address_line_1 ? (
                  <>
                    <p style={{ marginBottom: 3 }}>
                      <strong>
                        {invoice?.supplier ? "Supplier:" : "Customer:"}
                      </strong>
                      <br />
                    </p>
                    <p>
                      {invoice?.supplier?.abn ? (
                        <a
                          href={`https://abr.business.gov.au/ABN/View?abn=${invoice?.supplier?.abn}`}
                        >
                          {invoice?.supplier?.name}
                        </a>
                      ) : (
                        invoice?.counterparty?.name
                      )}

                      <br />
                      {invoice.counterparty?.address_line_1}
                      <br />
                      {invoice.counterparty?.address_line_2}
                    </p>
                  </>
                ) : (
                  <p>
                    <strong>
                      {invoice?.supplier ? "Supplier:" : "Customer:"}
                    </strong>{" "}
                    {invoice?.supplier?.abn ? (
                      <a
                        href={`https://abr.business.gov.au/ABN/View?abn=${invoice?.supplier?.abn}`}
                      >
                        {invoice?.supplier?.name}
                      </a>
                    ) : invoice?.supplier ? (
                      invoice?.supplier.name
                    ) : (
                      invoice?.counterparty?.name
                    )}
                  </p>
                )}
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs="8" md="6">
                Description
              </Col>
              <Col className="d-none d-sm-block" md="1">
                Qty
              </Col>
              <Col className="d-none d-sm-block" md="2">
                Unit Price
              </Col>
              <Col className="d-none d-sm-block" md="1">
                GST
              </Col>
              <Col xs="4" md="2">
                <p className="text-right">
                  <b>
                    Amount
                    {invoice?.status === "PRO_FORMA" ||
                    invoice?.status === "TEST_PRO_FORMA" ? (
                      <>
                        <br />
                        (AUD)
                      </>
                    ) : (
                      ""
                    )}
                  </b>
                </p>
              </Col>
            </Row>
            <hr className="mt-0 mb-5" />
            {invoice?.line_items.length > 0 ? (
              invoice?.line_items.map((lineItem, index) => (
                <Row key={index}>
                  <Col xs="8" md="6">
                    <p>{lineItem.description}</p>
                  </Col>
                  <Col className="d-none d-sm-block" md="1">
                    <p>
                      {parseFloat(lineItem.quantity).toFixed(
                        detectQuantityMinimumSignificantFigures(
                          total,
                          invoice?.line_items
                        )
                      )}
                    </p>
                  </Col>
                  <Col className="d-none d-sm-block" md="2">
                    <p>
                      {new Intl.NumberFormat("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      }).format(lineItem.unit_price)}
                    </p>
                  </Col>
                  <Col className="d-none d-sm-block" md="1">
                    <p>{parseFloat(lineItem.tax_rate).toFixed(0)}%</p>
                  </Col>
                  <Col xs="4" md="2">
                    <p className="text-right">
                      {new Intl.NumberFormat("en-AU", {
                        style: "currency",
                        currency: "AUD",
                      }).format(
                        (
                          parseFloat(lineItem.quantity) *
                          parseFloat(lineItem.unit_price)
                        ).toFixed(2)
                      )}
                    </p>
                  </Col>
                </Row>
              ))
            ) : (
              <Row>
                <Col xs="12">
                  <p className="text-center">
                    This is a test invoice,{" "}
                    <a href="/dashboard/live-invoice/add">continue editing</a>{" "}
                    to add line items, or{" "}
                    <a href="/register">sign up to accountedfor</a> to send
                    invoices that get paid quicker.
                  </p>
                </Col>
              </Row>
            )}
            <hr />
            <div className="pl-lg-4">
              <Row>
                <Col xs="2" md="7"></Col>
                <Col xs="5" md="3" className="text-right">
                  <h6>Subtotal</h6>
                </Col>
                <Col xs="5" md="2" className="text-right">
                  <h6>{subtotalFormatted}</h6>
                </Col>
              </Row>
              <Row>
                <Col xs="2" md="7"></Col>
                <Col xs="5" md="3" className="text-right">
                  <h6>GST</h6>
                </Col>
                <Col xs="5" md="2" className="text-right">
                  <h6>{gstFormatted}</h6>
                </Col>
              </Row>
              <Row>
                <Col xs="2" md="7"></Col>
                <Col xs="5" md="3" className="text-right">
                  <h5>
                    Total{" "}
                    {invoice?.status === "PRO_FORMA" ||
                    invoice?.status === "TEST_PRO_FORMA"
                      ? "AUD"
                      : ""}
                  </h5>
                </Col>
                <Col xs="5" md="2" className="text-right">
                  <h5>{totalFormatted}</h5>
                </Col>
              </Row>
            </div>
            <hr />
            <div className="pl-lg-4">
              <Row>
                <Col xs="12">
                  {invoice?.notes ? (
                    <>
                      <p>
                        <strong>Notes:</strong>
                      </p>
                      <p>{invoice?.notes}</p>
                    </>
                  ) : undefined}
                </Col>
              </Row>
            </div>
            {invoice && <LiveInvoicePay invoice={invoice} />}
            <br />
          </CardBody>
          <CardFooter>
            <Row>
              <Col
                xs="12"
                className={
                  showStripeBetaFeature && !invoice?.remittance.external_bsb
                    ? "d-none"
                    : "text-right"
                }
              >
                {invoice?.status === "EXPENSE" ? (
                  <Button
                    color="primary"
                    onClick={() => setAttachmentModalIsOpen(true)}
                    size="md"
                  >
                    View Attachment
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    href={`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${params.uuid}/download`}
                    size="md"
                  >
                    Download PDF
                  </Button>
                )}
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Container>

      <br />
      <p className="text-center">
        Need to send a professional Invoice but don't want to manage another
        password or tax issues?
      </p>
      <p className="text-center mb-0">
        Check out{" "}
        <a href="https://www.accountedfor.com.au">accountedfor.com.au</a>.
      </p>
      <p className="text-center">We've got your whole business accountedfor.</p>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default CustomerLiveInvoice;
